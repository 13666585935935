// ColorPicker/ColorPicker.js
import React from 'react';

const ColorPicker = ({ value, onChange }) => {
    return (
        <input
            type="color"
            aria-label="Colorpicker"
            value={value !== null ? '#' + value : ''}
            onChange={onChange}
            className="colorpicker"
        />
    );
};

export default ColorPicker;
