// Footer/Footer.js
import React from 'react';
//import PropTypes from 'prop-types';
import './Header.css';

const Header = () => {
  return (
    <div className="content">
        <div className="branding">
            <h1><img id="logo" src="./logo.png" width="82" height="47" alt="ROLO COLO" /></h1>
        </div>
        <h2>Quickly inverted colors for creatives and developers</h2>
        <p>Enter a valid 6-digit HEX color value or use the color picker to convert a color to it's inverted value.</p>
    </div>
  );
};

// Add.propTypes = {
//   to: PropTypes.string.isRequired,
// };

export default Header;
