import React, { useState } from 'react';
//import AppRouter from './AppRouter';
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import ColorPicker from './components/ColorPicker';
import CopyToClipboard from './components/CopyToClipboard';

const App = () => {
    const [baseColor, setBaseColor] = useState('FFFFFF');
    const [invertedColor, setInvertedColor] = useState('000000');

    const invertHex = (hex) => {
        return (Number(`0x1${hex}`) ^ 0xFFFFFF).toString(16).substr(1).toUpperCase();
    };

    const handleBaseInputChange = (event) => {
        let updatedValue = event.target.value.replace(/^#/, '');
        updatedValue = updatedValue.slice(0, 6);

        if (updatedValue.length === 6) {
            let invertedColor = invertHex(updatedValue);

            // Update negative side
            setInvertedColor(invertedColor);
        }
        setBaseColor(updatedValue);
    };

    const handleColorPickerChange = (event) => {
        let selectedColor = event.target.value.replace(/^#/, '');

        // Update base input value to same value as color picker
        setBaseColor(selectedColor);

        // Update negative side
        let invertedColor = invertHex(selectedColor);
        setInvertedColor(invertedColor);
    };

    return (
        <div className="app">
            <div className="flex">
                <div className="light base">
                    <Header />

                    <div className="input-wrapper">
                        <input
                            type="text"
                            aria-label="HEX Color"
                            placeholder="FFFFFF"
                            value={baseColor !== null ? '#' + baseColor : ''}
                            onChange={handleBaseInputChange}
                            onFocus={(event) => event.target.select()}
                        />
                        <div className="colorpicker-wrapper">
                            <ColorPicker value={baseColor} onChange={handleColorPickerChange} />
                        </div>
                    </div>

                    <div className="input-wrapper">
                        <input
                            type="text"
                            aria-label="Inverted HEX Color"
                            placeholder="000000"
                            value={invertedColor !== null ? '#' + invertedColor : ''}
                            disabled
                        />
                        <div className="colorpicker-wrapper readonly">
                            <input
                                className="colorpicker"
                                type="color"
                                aria-label="Inverted Color Preview"
                                placeholder="000000"
                                value={invertedColor !== null ? '#' + invertedColor : ''}
                                readOnly
                            />
                        </div>
                    </div>

                    <CopyToClipboard color={invertedColor} initialText="Copy to Clipboard" />
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default App;
