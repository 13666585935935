// CopyToClipboard/CopyToClipboard.js
import React, { useState } from 'react';
import './CopyToClipboard.css';

const CopyToClipboard = ({ color, initialText }) => {
    const [buttonText, setButtonText] = useState(initialText);

    const handleClipboardCopy = () => {
        const textToCopy = '#' + color;

        navigator.clipboard.writeText(textToCopy)
            .then(() => {
                setButtonText('Copied!');
                setTimeout(() => {
                    setButtonText(initialText);
                }, 3000);
            })
            .catch(err => {
                console.error('Failed to copy: ', err);
            });
    };

    return (
        <button className="clipboard" onClick={handleClipboardCopy}>{buttonText}</button>
    );
};

export default CopyToClipboard;
