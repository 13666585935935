// Footer/Footer.js
import React, { useState, useEffect } from 'react';
import './Footer.css';

const Footer = () => {

  const [version, setVersion] = useState('');

  useEffect(() => {
    fetch('/manifest.json')
      .then(response => response.json())
      .then(data => {
        setVersion(data.version);
      })
      .catch(error => {
        console.error('Error fetching manifest:', error);
      });
  }, []);

  return (
    <div className="legal">
        <span className="copyright"><a href="https://www.reinhardmeisl.com/" target="_blank" rel="noopener noreferrer">©2024 Reinhard Meisl</a></span>
        <span><a href="https://www.reinhardmeisl.com/legal/" target="_blank" rel="noopener noreferrer">Legal</a></span>
        <span><a href="https://www.reinhardmeisl.com/privacy/" target="_blank" rel="noopener noreferrer">Privacy</a></span>
        <span>v{version}</span>
    </div>
  );
};

export default Footer;
